import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Capricorn.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
              Capricorn Nature
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/capricorn"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Capricorn </h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Capricorn Nature</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Capricorn Nature</h1>
                <h2 className="text-sm md:text-base ml-4">Dec 22 - Jan 19</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Capricorn Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Capricorn Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Capricorn Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-love");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-nature");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-man");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-traits");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-facts");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="playfair text-black text-xl md:text-3xl">Capricorn Nature</p><br/>
           The symbol of your Sign is Goat, and like a mountain goat, your wish to climb higher and higher in every walk of life. This shows incredible ambition. You are self-confident and want to set high goals. You feel happy when others respect you for your abilities. You do not bypass taking risks, but you are not impetuous. All your movements are rehearsed before placing them into action. Your connections with people can be complicated. Sometimes you became very selfish, and have an h5 desire to earn power that will boost your status in a community. <br/>
           <br></br>
           You look to be cool and quiet, but below the shell, you can be a very emotional kinda person. You are fully self-motivated and will handle to find chances whenever needed. Your abilities of concentration are good, but you can be rather emotionless and may have too high a thought about yourself. Get your birth kundali free to find out what destiny has in store for you. <br/>
           <br></br>
           <p className="font-bold text-orange-500 inline-block mr-2">Capricorn Key Planet:</p>Saturn<br/>
           The ringed planet Saturn is usually linked with the word “Satan”. The planet signifies limitations and boundaries. If you provide into those rules and regulations, you are bound to live within the rules of causes and impact. But if you do some spiritual training, you can be awarded great learning, knowledge, and wisdom. Saturn signifies that you get what you deserve. If you attempt to take shortcuts in life, you may get into a problem later on. But if you are willing to do your work sincerely, then you will be rewarded for sure. Talk to the best and genuine astrologer to get a better knowledge of this aspect.<br/>
           <br></br>
           <p className="font-bold text-orange-500 inline-block mr-2">Tenth House:</p>Career<br/>
           The 10th House is the House of the Father, which impacts your work or profession. This House also indicates what kind of thoughts the world has about you, via your instinct. You can rely on its energy to help you get the colonial and professional status you want. If your job condition is causing you stress, pressure, or anxiety, check out Bodhi's personalized Birth Chart absolutely free.<br/>
           <br></br>
           <p className="font-bold text-orange-500 inline-block mr-2">Capricorn Element:</p>Earth<br/>
           Your Sign is associated with the element Earth. The most impressive quality of this Sign is that you are sensible. However, you lack spontaneity. All your actions are pre-planned in advance. You can’t endure failure, and all your struggles will be geared towards completing sure that you get the victory you desire. <br/>
           <br></br>
           For you, seeing is acknowledging. You will only accept the things seen, touched, or tasted. You are natural and oppose what is idealistic. And, it is precise because you are natural that others value your opinion highly. Get the Birth Chart based service Personal questions to resolve any suspicions or doubts that you may be encouraging about your potential.<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Capricorn Strength</p><br/>
           You are defined in your efforts and don’t let lapses affect you. You will keep pushing till you succeed. Your other optimistic qualities are that you are dedicated, reliable, trustworthy, patient, and faithful. Your ability in devastating obstacles strengthens you.<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Capricorn Weakness</p><br/>
           Though you are self-confident, you have your pessimistic points. Often, you are selfish and would evade taking any move in which you don't earn anything. You're dreaming to achieve your goals by any means that can periodically make you harsh in your motions. You can also be devoid of sentiment. You might also be inquisitive to know what's in Capricorn traits.<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Capricorn Habits</p><br/>
           The Capricorn-born residents are very laborious and give more priority to their profession than their domestic lives. This quality derives from their fear of poverty and holding to depend on others. The main reason for many of them performing so hard and for such long hours is that they don’t get the time to expend the money, and can thus save it for the future. They should be more mindful of their non-material blessings. They can occasionally ignore their fitness by not paying attention to their diet. You need to be careful about your health. You can check your daily horoscope free.<br/>
           <br></br>
           If you have any queries related to your health, finance, career, or love relationships, our best and most genuine Astrologers are here to get rid of your problems.<br/>
           <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
